import { forwardRef, useEffect, useRef, useState } from "react"
import { WhatWeDoStyle } from "./WhatWeDo.style"
import { useOnScreen } from "../../hooks/useOnScreen"
import data from "./data"
import { Title } from "../Animations"

const WhatWeDo = forwardRef(({ onScreen }, ref) => {
  const [animationClass, setAnimationClass] = useState("")

  const showNextAnimation = () => {
    if (!animationClass) {
      setAnimationClass("fade-in")
    }
  }
  const [anim, setAnim] = useState("")
  const cardRef = useRef(null)
  const whatWeDoScreen = useOnScreen(cardRef, [0.4, 0.4])

  useEffect(() => {
    if (whatWeDoScreen) {
      setAnim("fade-in")
    }
  }, [whatWeDoScreen])

  return (
    <WhatWeDoStyle ref={ref} id="what-we-do">
      <div className="container">
        <h4 className="section-title">How can we help you?</h4>
        <div ref={cardRef} className={`card-wrapper animated ${anim}`}>
          {data.map(({ title, list }, index) => {
            return (
              <div
                className="card"
                key={index}
                onAnimationEnd={showNextAnimation}
              >
                <Title
                  as="h3"
                  className="card-title"
                  startAnim={onScreen}
                  title={title}
                />
                <ul className={`animated ${animationClass}`}>
                  {list.map((item, index) => {
                    return <li key={index}>{item}</li>
                  })}
                </ul>
              </div>
            )
          })}
        </div>
      </div>
    </WhatWeDoStyle>
  )
})

export default WhatWeDo
