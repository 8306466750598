import React from 'react';
import Logo from "../../assets/logo.svg";
import {Link} from "react-router-dom";
import {PolicyStyle} from "./Policy.style";

const Policy = () => {
    return (
        <PolicyStyle>
            <div className="container">
                <header>
                    <Link className="logo" to='/'>
                        <img src={Logo} alt="NFTStack" />
                    </Link>
                </header>
                <div className="content">
                    <h2 className='page-title'>Policy & Procedures</h2>
                    <h3 className='title'>Communication and management</h3>
                    <h4 className='block-title'>Basic terminology</h4>
                    <div className="block">
                        <p><span>Base character</span> is the main unchangeable part of the art (mainly its body shape)</p>
                        <p><span>Traits</span> are the changeable assets we create in order to generate the whole collection based on our main character. Trait examples are jungle background, cowboy hat, cigar, armor, sunglasses, etc.</p>
                        <p><span>Layers</span> are categories of similar traits. Layer examples are backgrounds, skins, hats, eyes, clothes, etc</p>
                        <p><span>Trait</span> list is an online sheet of all traits divided into layers.</p>
                        <p><span>Overlapping</span> is a generation process problem that occurs when two or more traits are not fitting into their respective layers and get over each other, crashing the integrity of the art.</p>
                        <p><span>Rarity score</span> is the proportion of each single trait usage in the whole collection, usually collections have common, rare, and super rare traits.
                        </p>
                        <p><span>Rarity list</span> is the final form of the trait list including rarity scores for all traits.</p>
                        <p><span>Specials</span> are unique NFTs used for giveaways or celebrity promotions.</p>
                        <p><span>Generation script</span> is a custom-developed code based on the rarity list that is implemented to the art and traits to generate a collection.</p>
                        <p><span>Generation</span> is the process of the creation of the whole collection with the implementation of the generation script based on the base character and traits.</p>
                        <p><span>Metadata</span> is the description of the NFT. For Example, Metadata contains the URL of Art, Name, description, etc. Metadata is provided in JSON format</p>
                        <p><span>Minting</span> is the process of creation of an NFT with the art and metadata on the blockchain through the smart contract.</p>
                        <p><span>The smart contract</span> is a coded program that enables you to create an NFT collection on the blockchain, as well as control-related processes like minting, whitelisting, etc.</p>
                        <p><span>The minting option for the website</span> is part of the code implemented to your website giving a possibility to organize the minting process straight from your website.</p>
                    </div>
                    <h4 className='block-title'>Project management processes</h4>
                    <div className="block">
                        <ul>
                            <li>Each project is divided into several milestones with the exact works mentioned. </li>
                            <li>We’re asking for 50% of the project amount upfront to get started. The rest of the money will be divided into milestones. You will be asked to transfer the respective amount of each milestone once it is completed.</li>
                            <li>Please note, that work for each milestone starts only after the completed milestone is paid.</li>
                            <li>Milestones for art creation and development are separated and start simultaneously with the launch of the project.</li>
                            <li>Our management system is built around project managers, who handle all work on our side and keep constant communication with partners. Because the managers supervise the work of a big community of our specialists, and direct contacts take more time, as well as increase the chances of misunderstanding (most of the artists are not proficient in English), and negatively affect the planning and management of the project in general, you will be in contact with project managers only and not the artists or developers</li>
                            <li>In case our specialists do not provide the work with the proper quality we will refund the respective amount of the milestone.</li>
                            <li>We take responsibility for keeping all the deadlines. However, if any delay happens because of the lack of communication or delays from the client’s side, the respective number of days will be added to the initial deadline.</li>
                        </ul>
                    </div>
                    <h4 className='block-title'>Communication and availability</h4>
                    <div className="block">
                        <ul>
                            <li>Most of our team is located in Yerevan, Armenia (UTC +4).</li>
                            <li>Most of the communication will be done through chats on Discord, WhatsApp, Telegram. Written chats are the best way to avoid misunderstanding or missing any information.</li>
                            <li>Availability for calls with our managers is between 8AM to 4PM UTC.</li>
                            <li>When starting a project your Project Manager will organize a kickoff meeting to explain all the processes and answer all the questions you have.</li>
                            <li>Project Managers will organize follow up calls throughout the project, to keep you up to date about the process and project status.</li>
                            <li>You will be asked for unscheduled calls in case of emergencies.</li>
                            <li>In case of emergencies you can contact your Project Manager to schedule a call.</li>
                            <li>Please note we do not work during the weekends.</li>
                        </ul>
                    </div>
                    <h4 className='block-title'>Concept and base character</h4>
                    <div className="block">
                        <p>The first step is the development of your main character.</p>
                        <ul>
                            <li>First, we work on the idea of the collection. It would be great if you know exactly what you need, but don’t worry if you have none, we will help you find the best collection.</li>
                            <li>Second, we choose what kind of art will be used (basic illustrations, detailed comic style art, pixel art, 3D art or animation); the cost of the collection changes hugely depending on this.</li>
                            <li>Once we have decided about the “character identity” and style of our art, our artists will start working on the concept. This can take a couple of days, based on the level of art’s details and complexity of the character.</li>
                            <li>After you get the initial concept, you will be asked for detailed feedback. Please note that at this point you are asked to give feedback on the character itself (style, colors, body shape, position) and not on the traits like hats, clothes, etc. That will be discussed at later stages.</li>
                            <li>We make changes based on your feedback and send it back to you for the final revision; here you will be asked to give the feedback on the small changes left, so our artists can create the final character.</li>
                        </ul>
                        <p>This phase requires very tight communication because all the traits will be developed based on the concept character we create here.</p>
                    </div>
                    <h4 className='block-title'>Traits lists</h4>
                    <div className="block">
                        <p>How is the trait list created?</p>
                        <ul>
                            <li>Our project manager will offer you the best layer combinations for the whole collection based on the concept and create the google sheet with the table of traits,</li>
                            <li>Deciding the final layers and traits,</li>
                            <li>Filling in your ideas and traits you want into the sheet,</li>
                            <li>Our team checking if there are traits that will create overlapping problems,</li>
                            <li>Finalizing the trait list in the google sheet,</li>
                            <li>Creating the Trello board with all traits for the art development process,</li>
                            <li>You will be asked to attach references and comments to all the traits in the trello board. This is done to avoid possible misunderstandings and decrease the number of possible revisions.</li>
                            <li>Overlapping traits will be asked to be changed or removed in order to avoid further problems during the generation period.</li>
                        </ul>
                    </div>
                    <h4 className='block-title'>Trait development</h4>
                    <div className="block">
                    <ul>
                        <li>Trait development will be divided into milestones and you will be provided a list of the traits developed per milestone.</li>
                        <li>You can only delete or change a trait before the start of the respective milestone, and not once it has been started.</li>
                        <li>Standard price packages include 110-120 traits, adding more traits is associated with additional costs and deadline changes.</li>
                        <li>After each milestone you will be asked for general feedback on the traits, which should be taken into account for the development of the future traits.</li>
                        <li>You will be asked to leave comments of any changes and revisions under each trait in your trello board ongoingly, but all the revisions will be completed at the end of the art creation
                            and NOT ongoingly. Based on our experience this is the best practice for finishing the art on promised deadlines, as well as avoiding misunderstandings or getting stuck in the infinite loop of revisions.</li>
                        <li>If you delete an already created trait, it still counts in the total number.</li>
                    </ul>
                    </div>
                    <h4 className='block-title'>Revisions</h4>
                    <div className="block">
                        <ul>
                            <li>During the whole project, you will be asked to leave comments on the Trello board under each trait. Once the traits are completed our artists will go over the comments and make all requested revisions. </li>
                            <li>Why don’t we do ongoing revisions? The most common reason for delays and project development issues happens when partners demand ongoing changes on the traits and we get stuck in the endless cycle of redoing the same things instead of finishing the whole collection. So our experience of over 40 NFT projects highlights the importance of sticking to the initial plan of development. When all traits are ready, as a client you will have a whole picture and would be able to see which traits you want to be changed or modified.</li>
                            <li>Each project has the opportunity to make revision for up to 35% of the traits, requesting additional changes </li>
                        </ul>
                    </div>
                    <h4 className='block-title'>Specials</h4>
                    <div className="block">
                        <p>Special NFTs are the unique ones with their own traits, which are usually used for holiday promotions and giveaways (Halloween character) or for promotional purposes when doing influencer marketing (exclusive character based on famous rapper’s own style).</p>
                        <ul>
                            <li>Standard price packages include up to 8 specials.</li>
                            <li>Specials are created after the creation of the main traits.</li>
                            <li>You will be asked to provide exact references for the specials, including for whom the special will be done and what traits you want for each layer.</li>
                            <li>Creation of specials can take several days depending on the difficulty of the traits and accuracy of the provided information, that’s why they are created after the main traits in order not to affect the main deadlines of the project.</li>
                            <li>Specials are also eligible for feedback and revisions.</li>
                        </ul>
                    </div>
                    <h4 className='block-title'>Other visual content</h4>
                    <div className="block">
                        <p>Standard packers include the following visual content, any further requests are related with additional costs and deadline changes and should be discussed with your project manager.</p>
                        <ul>
                            <li>Examples of the NFT from test generation.</li>
                            <li>Twitter and Discord banners.</li>
                            <li>Revealing image to use before the actual airdropping.</li>
                        </ul>
                    </div>
                    <h3 className='title'>Generation and Development</h3>
                    <h4 className='block-title'>Test generations</h4>
                    <div className="block">
                        <p>STest generations are conducted a couple of times during the project to check and identify overlapping problems prior to the final generation, as well as to provide you with art examples for marketing purposes.</p>
                        <ul>
                            <li>During the project we will provide you with 3 test generation patches, after having completed each of the art milestones. You will receive 10, 500 and 1000 examples after each milestone, respectively.</li>
                            <li>Rarities are not taken into account during a test generation, it is just a random generation.</li>
                            <li>The art created during the test generation will not be in the final generation, because the generation script works randomly every time and the number of traits used with their rarities affect the final generation outcome.</li>
                        </ul>
                    </div>
                    <h4 className='block-title'>Rarity list</h4>
                    <div className="block">
                        <p className='underline'>Duration 1-2 days</p>
                        <p>A rarity list is a matrix of how your traits will be distributed during the generation. You will be asked to choose the rare traits and how many times they will be seen in the whole collection. Our specialists will guide you through this process and based on the provided information they will create the final rarity list with counts for all traits and their rarity scores.</p>
                    </div>
                    <h4 className='block-title'>Final Generation and Metadata</h4>
                    <div className="block">
                        <p className='underline'>Duration 4-7 days (depending on the difficulty of art)</p>
                        <ul>
                            <li>Based on the rarity list our specialists develop a generation script for your collection, which combines the traits keeping counts and rarity score mentioned in the Rarity list. At this point you will have all the future NFT pictures for your collection.</li>
                            <li>We will ask you to open an account at <a className='link' href="https://www.pinata.cloud/" target="_blank" rel='noreferrer'>Pinata | Your Home for NFT Media</a> and share your pinata server secret and server key, so we can upload all the generated art to your own cloud server, to which we won’t have any further access.</li>
                            <li>Once all the art is generated and uploaded to the server, we will create and provide you the metadata files.</li>
                        </ul>
                    </div>
                    <h4 className='block-title'>Smart contract</h4>
                    <div className="block">
                        <p className='underline'>Duration 3-4 days</p>
                        <p>The smart contract is the part that allows your NFT art to transform into real NFTs.</p>
                        <ul>
                            <li>We will ask you for important information about your collection (minting price, max minting count, etc.), and based on that develop your smart contract, test it and send you all the needed files.</li>
                            <li>Once our smart contract and appropriate files are ready, we will send everything over to you with detailed guidelines on how to create and verify your smart contract.</li>
                            <li>Please note, we do not ask our clients for any private information, and we strongly advise you to not provide it to anyone, even people to whom you outsource your development processes.</li>
                        </ul>
                    </div>

                </div>
            </div>


        </PolicyStyle>
    );
};

export default Policy;
