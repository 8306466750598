import { FooterStyle } from "./Footer.style"
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <FooterStyle>
      <div className="container">
          <div className="content">
              <p className="copyright">© NFTSTACK {new Date().getFullYear()}</p>
              <Link to='/policy-and-procedures' target="_blank">Policy & Procedures</Link>
          </div>
      </div>
    </FooterStyle>
  )
}

export default Footer
