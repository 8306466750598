export const navList = [
  {
    title: "WHAT WE DO",
    to: "#what-we-do",
  },
  {
    title: "WORKS",
    to: "#works",
  },
  {
    title: "CONTACT",
    to: "#contact",
  },
]
