import Web from "../../assets/what-we-do/web.png"
import WebWebp from "../../assets/what-we-do/web.webp"
import SmartContract from "../../assets/what-we-do/smart-contract.png"
import SmartContractWebp from "../../assets/what-we-do/smart-contract.webp"
import Logo from "../../assets/logo.svg"

const baseImgUrl = "/assets/carousel/"

export const projImgList = [
  // `${baseImgUrl}1_1.jpg`,
  `${baseImgUrl}1_2.jpg`,
  // `${baseImgUrl}1_3.jpg`,
  `${baseImgUrl}1_4.jpg`,
  `${baseImgUrl}1_5.jpg`,
  `${baseImgUrl}1_6.jpg`,
  `${baseImgUrl}1_7.jpg`,
  `${baseImgUrl}1_8.jpg`,
]

export const projListDrawer = [
  // {
  //   title: "The Big Cats",
  //   preview:
  //     "The Big Cats NFT will produce a collection of 5000 pieces with each piece of their DNA shared and protected via the Ethereum Blockchain.",
  //   websiteUrl: "https://bigcatsnft.com/",
  //   socialUrls: {
  //     instagram: "https://www.instagram.com/bigcats.nft/?utm_medium=copy_link",
  //     twitter: "https://mobile.twitter.com/bigcats_nft",
  //   },
  //   description1:
  //     "Legendary for their agility, intelligence, and ferocity, the Big Cats have been prized since the time of the Pyramids. In 2022, the Big Cats have evolved into their final, most dominant form, and are ready to be uncaged — destined to rule the Metaverse.",
  //   description2:
  //     "But be warned though — these Big Cats are exceptionally rare. To even catch a glimpse of them in the Metaverse is a privilege. To actually own an original Big Cat is to walk amongst virtual  gods. \\n Big Cats NFTs will be the world's most refined and exclusive collection of provably rare digital assets, stored for eternity on the blockchain.",
  //   description3:
  //     "Only 5000 Big Cats will be minted, preserving their majesty and influence for a select few who wish to be revered in the Metaverse forever. Every Big Cat will be designed by world-class artists with savage instincts for detail, quality, and adaptability. In a game of cat and mouse, the Big Cat always wins. Now is the time. Jump on your chance to own your very own Big Cat.",
  //   gallery: {
  //     img1: `${baseImgUrl}1_1.jpg`,
  //     img2: `${baseImgUrl}2_1.jpg`,
  //     img3: `${baseImgUrl}3_1.jpg`,
  //     img4: `${baseImgUrl}4_1.jpg`,
  //     img5: `${baseImgUrl}5_1.jpg`,
  //   },
  // },
  {
    title: "Racing Social Club",
    preview:
      "Racing Social Club is a collection of 7878 Racers who are randomly generated on the Ethereum blockchain. The community of racing fans and NFT enthusiasts will gain access to our most exclusive events, which we will organize multiple times a year.",
    websiteUrl: "https://racingsocialclub.com/",
    socialUrls: {
      instagram: "https://www.instagram.com/racingsocial_nft/",
      twitter: "https://twitter.com/racingsocialnft",
    },
    description1:
      "Racing Social Club aims to take an exclusive place in the NFT and racing industry. The goal is to build a likeminded community of racing fans and NFT enthusiasts. We will build the RSC brand by adding partnerships with professional racers and growing our network in the industry.",
    description2:
      "We will build up the momentum to prepare for the qualification as best as we can. Growing the community during the free practice will be done by adding real value and getting the right attention. This will be done through events like crazy marketing stunts, professional content, giveaways, AMA’s and many more!",
    gallery: {
      // img1: `${baseImgUrl}1_2.jpg`,
      img1: `${baseImgUrl}removable.jpg`,
      // img2: `${baseImgUrl}2_2.jpg`,
      img3: `${baseImgUrl}3_2.jpg`,
      img4: `${baseImgUrl}4_2.jpg`,
      img5: `${baseImgUrl}5_2.jpg`,
    },
  },
  // {
  //   title: "Supreme Kong",
  //   preview: "THE SUPREME LEADERS OF THE METAVERSE",
  //   websiteUrl: "https://supremekong.com/",
  //   socialUrls: {
  //     instagram: "https://www.instagram.com/supremekong.nft/",
  //     twitter: "https://twitter.com/Supremekongnft",
  //   },
  //   description1:
  //     "The cage of reality has been shaken to the ground, and the blockchain struggles to contain its power. Supreme Kong has been unleashed and is ready to dominate the Metaverse. The Supreme Leader of the Metaverse Kingdom — Supreme Kong. Supreme Kong is an NFT series as limited as it is powerful, only 2000 Supreme Kongs shall be minted.",
  //   description2:
  //     "A primal, unstoppable force that cannot be tamed, Supreme Kong is impossible to be replicated or destroyed. Supreme Kong dominates the Metaverse in any domain, thanks to its fully imagined, unrestrained 3D form. Whether it be in movies, games, or real-time Metaverse meet-ups, your authority and influence will not be challenged.Now is the time for you to evolve into your most savage, furious form.Now is the reign of Supreme Kong.",
  //   gallery: {
  //     img1: `${baseImgUrl}1_3.jpg`,
  //     img2: `${baseImgUrl}2_3.jpg`,
  //     img3: `${baseImgUrl}3_3.jpg`,
  //     img4: `${baseImgUrl}4_3.jpg`,
  //     img5: `${baseImgUrl}5_3.jpg`,
  //   },
  // },
  {
    title: "Camp Pluto",
    preview:
      "Camp Pluto is the first ever members-only campground where memberships are bought on the Ethereum blockchain as NFTs",
    websiteUrl: "https://camppluto.io/",
    socialUrls: {
      instagram: "https://www.instagram.com/camp_pluto",
      twitter: "https://twitter.com/camp_pluto",
    },
    description1:
      "Camp Pluto is a modern way to enjoy nature. Introducing the world's first member-only private campground where memberships are purchased on the blockchain as NFTs.",
    description2:
      "Each of the 4,500 Camp Pluto NFTs will allow for governance, access to a community-selected piece of physical land in the United States, and access to future projects. Camp Pluto NFTs will be paramount to the future of nature conservation",
    gallery: {
      img1: `${baseImgUrl}1_4.jpg`,
      img2: `${baseImgUrl}2_4.jpg`,
      img3: `${baseImgUrl}3_4.jpg`,
      img4: `${baseImgUrl}4_4.jpg`,
      img5: `${baseImgUrl}5_4.jpg`,
    },
  },
  {
    title: "Sneaker Heads",
    preview:
      "Sneaker Heads is an NFT collection made by the artist Ali Dawood.",
    websiteUrl: "https://sneakerheads.xyz",
    socialUrls: {
      twitter: "https://twitter.com/sneakerheadsoff",
    },
    description1:
      "The project aims to bring the Sneaker culture in the NFT space. This collection is the initial drop and then many drops and collaborations are anticipated.",
    gallery: {
      img1: `${baseImgUrl}1_5.jpg`,
      img3: `${baseImgUrl}3_5.jpg`,
      img4: `${baseImgUrl}4_5.jpg`,
    },
  },
  {
    title: "K9 SQUAD",
    preview:
      "Do yourself a favor and join the pride. Become a K9 SQUAD member. Created to honor man’s best friend, so both man and dog can benefit from the rapidly growing NFT space.",
    websiteUrl: "https://k9squad.io",
    socialUrls: {
      twitter: "https://mobile.twitter.com/K9SQUADNFT",
      instagram: "https://www.instagram.com/k9squadnft/?igshid=YmMyMTA2M2Y=",
    },
    description1:
      "K9 SQUAD was founded out of a desire to put the interests of dogs and their owners first. But how does this work, isn't it one or the other? Let me explain. By joining the squad you can proudly show your NFT to the outside world. People know what we stand for and therefore also what you stand for when buying this NFT.",
    description2:
      "Done by one of the best 2D artists out there. Angry Birds was one of his many successful projects.",
    gallery: {
      img1: `${baseImgUrl}1_6.jpg`,
      img2: `${baseImgUrl}2_6.jpg`,
      img3: `${baseImgUrl}3_6.jpg`,
      img4: `${baseImgUrl}4_6.jpg`,
      img5: `${baseImgUrl}5_6.jpg`,
    },
  },
  {
    title: "Donny Doo’s",
    preview:
      "The message we want to spread is simple, but important to us and hopefully, to many more. \n" +
      "Every kid, no matter what race, background or upbringing, deserves a happy and healthy childhood. \n" +
      "We aim to raise awareness and provide help where it’s needed the most, but we need you, too!",
    websiteUrl: "https://donnydoos.com/",
    socialUrls: {
      twitter: "https://twitter.com/TheDonnyDoos",
    },
    description1:
      "We are looking for brave individuals with similar belief system to join us on our incredibly challenging journey. In return, an action plan was put in place to provide value for those that would. \n" +
      "Together, we can do it both. \n" +
      "Kindness will always prevail, and community led by good people will prove that.\n",
    description2:
      "Introduction to our story, team and the vision of the project will be done through our website, social media platforms and will follow up with regular AMAs.",
    gallery: {
      img1: `${baseImgUrl}1_7.jpg`,
      img2: `${baseImgUrl}2_7.jpg`,
      img3: `${baseImgUrl}3_7.jpg`,
      img4: `${baseImgUrl}4_7.jpg`,
      img5: `${baseImgUrl}5_7.jpg`,
    },
  },
  {
    title: "Bamboozles",
    preview:
      "A collection of cute Panda’s eating their way through the\n" +
      "MetaVerse 🎋 On a mission 🌍 Join the fun! 🌈 Designed by a\n" +
      "designer of Angry birds 🐧",
    websiteUrl: "https://bamboozles.io/",
    socialUrls: {
      twitter: "https://twitter.com/BamBoozle_NFT",
    },
    description1:
      "🎋Do you love pandas?🎋 \n" +
      "We sure do, and that’s why we created BamBoozles 🐼 – a collection\n" +
      "of adorable and fun-loving panda characters that are eating their\n" +
      "way trough the MetaVerse. They are bright, colorful, and bring\n" +
      "happiness to everyone they meet. In addition, they love giving\n" +
      "back! For every mint of a BamBoozle NFT, we will donate a part to\n" +
      "an endangered animal organization. Also, we are building a mobile\n" +
      "game in which you will have advantages if you hold a BamBoozle! \n",
    description2:
      "You can help make the world a better place just by being a part of\n" +
      "our family. We will work on developing and growing our strong and\n" +
      "organic community and the BamBoozles brand. 🌈",
    gallery: {
      img1: `${baseImgUrl}1_8.jpg`,
      img2: `${baseImgUrl}2_8.jpg`,
      img3: `${baseImgUrl}3_8.jpg`,
      img4: `${baseImgUrl}4_8.jpg`,
      img5: `${baseImgUrl}5_8.jpg`,
    },
  },
]

export const previewList = [
  // {
  //   title: "The Big Cats",
  //   description:
  //     "The Big Cats NFT will produce a collection of 5000 pieces with each piece of their DNA shared and protected via the Ethereum Blockchain.",
  // },
  {
    title: "Racing Social Club",
    description:
      "Racing Social Club is a collection of 7878 Racers who are randomly generated on the Ethereum blockchain. The community of racing fans and NFT enthusiasts will gain access to our most exclusive events, which we will organize multiple times a year.",
  },
  // {
  //   title: "Supreme King",
  //   description: "THE SUPREME LEADERS OF THE METAVERSE.",
  // },
  {
    title: "Camp Pluto",
    description:
      "Camp Pluto is the first ever members-only campground where memberships are bought on the Ethereum blockchain as NFTs.",
  },
  {
    title: "Sneaker Heads",
    description:
      "Sneaker Heads is an NFT collection made by the artist Ali Dawood.",
  },
  {
    title: "K9 SQUAD",
    description:
      "Do yourself a favor and join the pride. Become a K9 SQUAD member. Created to honor man’s best friend, so both man and dog can benefit from the rapidly growing NFT space.",
  },
  {
    title: "Donny Doo’s",
    description:
      "The message we want to spread is simple, but important to us and hopefully, to many more. \n" +
      "Every kid, no matter what race, background or upbringing, deserves a happy and healthy childhood. \n" +
      "We aim to raise awareness and provide help where it’s needed the most, but we need you, too!",
  },
  {
    title: "Bamboozles",
    description:
      "A collection of cute Panda’s eating their way through the\n" +
      "MetaVerse 🎋 On a mission 🌍 Join the fun! 🌈 Designed by a\n" +
      "designer of Angry birds 🐧",
  },
]
