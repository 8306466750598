import styled from "styled-components"
import Modal, { BaseModalBackground } from "styled-react-modal"

export const ModalStyle = styled.div`
  display: inline-block;
  .btn-text {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    cursor: pointer;
  }
`

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const FadingBackground = styled(BaseModalBackground)`
  transition: 0.3s;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
  z-index: 9999;

  .btn-close {
    position: absolute;
    top: 40px;
    right: 40px;
    height: 32px;
    line-height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: ${({ theme }) => theme.colors.white};

      svg {
        path {
          fill: ${({ theme }) => theme.colors.black} !important;
        }
      }
    }
  }
  iframe {
    padding: 0 24px;
  }
`
