import { useEffect, useState, useRef } from "react"
import scrollIntoView from "scroll-into-view"
import Header from "../components/Header/Header"
import Hero from "../components/Hero/Hero"
import WhatWeDo from "../components/WhatWeDo/WhatWeDo"
import HaveIdea from "../components/HaveIdea/HaveIdea"
import Contact from "../components/Contact/Contact"
import Footer from "../components/Footer/Footer"
import OurWork from "../components/OurWork/OurWork"
import { useOnScreen } from "../hooks/useOnScreen"
import { Button } from "../components/UIKit"
import Loader from "../components/Loader/Loader"
import { useLockedBody } from "../hooks/useLockedBody"

const Landing = () => {
    const [loading, setLoading] = useState(true)
    const [, setLocked] = useLockedBody(true)
    const [scrollTo, setScrollTo] = useState(null)
    const heroRef = useRef(null)
    const whatWeDoRef = useRef(null)
    const workRef = useRef(null)
    const haveIdeaRef = useRef(null)
    const contactRef = useRef(null)

    const heroScreen = useOnScreen(heroRef)
    const whatWeDoScreen = useOnScreen(whatWeDoRef, [0.4, 0.4])
    const workScreen = useOnScreen(workRef, [0.2, 0.4])
    const contactScreen = useOnScreen(contactRef)
    const haveIdeaScreen = useOnScreen(haveIdeaRef)
    useEffect(() => {
        window.history.scrollRestoration = "manual"
    }, [])

    const refMapping = {
        "#what-we-do": whatWeDoRef,
        "#works": workRef,
        "#contact": contactRef,
    }

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
            setLocked(false)
        }, 3000)
    })




    useEffect(() => {
        if (scrollTo) {
            scrollIntoView(refMapping[scrollTo]?.current, {
                align: {
                    top: 0,
                },
            })
            setScrollTo(false)
        }
    }, [scrollTo])

    const handleLinkClick = to => {
        setScrollTo(to)
    }

    return (
        <>
            <Loader className={loading ? "" : "hide-loader"} />
            <Header onLinkClick={handleLinkClick} />
            <div className="main-wrapper">
                <Hero ref={heroRef} onScreen={heroScreen && !loading} />
                <WhatWeDo ref={whatWeDoRef} onScreen={whatWeDoScreen && !loading} />
                <HaveIdea ref={haveIdeaRef} onScreen={haveIdeaScreen && !loading} />
                <OurWork ref={workRef} onScreen={workScreen && !loading} />
                <Contact ref={contactRef} onScreen={contactScreen && !loading} />
            </div>
        </>
    )
}

export default Landing
