import Modal from "styled-react-modal"
import { useState } from "react"
import { ModalStyle, StyledModal } from "./Modal.style"
import { Icon } from "../../UIKit"
import { theme } from "../../../styles/global/theme"

const ModalERC721A = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  return (
    <ModalStyle>
      <button className="btn-text" onClick={toggleModal}>
        {" "}
        ERC721A
      </button>
      <StyledModal
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
      >
        <iframe
          width="1280"
          height="720"
          src="https://www.youtube.com/embed/FLrl9NY8XZc?autoplay=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <button onClick={toggleModal} className="btn-close">
          <Icon name="close" size={16} color={theme.colors.white} />
        </button>
      </StyledModal>
    </ModalStyle>
  )
}

export default ModalERC721A
