import {Routes, Route, useLocation} from "react-router-dom";
import Landing from "./pages/Landing";
import Policy from "./pages/Policy/Policy";
import {Button} from "./components/UIKit";
import Footer from "./components/Footer/Footer";
import {useEffect, useState} from "react";

const App = () => {
    const [fixedButton, setFixedButton] = useState(false)
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            const hubspotButton = document.getElementById(
                "hubspot-messages-iframe-container"
            )
            if (window.pageYOffset > 600) {
                setFixedButton(true)
                hubspotButton.classList.add("fade-in-button")
            } else {
                setFixedButton(false)
                hubspotButton.classList.remove("fade-in-button")
            }
        })
    }, [])
  return (
      <main>
              <Routes>
                  <Route path="/" element={<Landing />} />
                  <Route path="/policy-and-procedures" element={<Policy />} />
                  <Route path="*" element={<Landing />} />
              </Routes>
          <Footer />
          <Button
              as="a"
              href="https://meetings.hubspot.com/ghazaryan"
              target="_blank"
              fixed
              className={fixedButton ? "fade-in-button" : ""}
          >
              Schedule A Call
          </Button>
      </main>

  )
}

export default App
