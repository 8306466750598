import styled from "styled-components"

export const FooterStyle = styled.footer`
  padding: 24px 0;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.md};
  
  .content {
    display: flex;
    
    a {
      text-decoration: underline;
      margin-left: 24px;
    }
  }
`
