import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { ThemeProvider } from "styled-components"
import { theme } from "./styles/global/theme"
import { GlobalStyle } from "./styles/global/globalStyles"
import { ModalProvider } from "styled-react-modal"
import { FadingBackground } from "./components/WhatWeDo/Modals/Modal.style"
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(

<BrowserRouter>
  <ThemeProvider theme={theme}>

    <ModalProvider backgroundComponent={FadingBackground}>
      <GlobalStyle />
      <App />
    </ModalProvider>
  </ThemeProvider>
      </BrowserRouter>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
