import styled from "styled-components"

export const PolicyStyle = styled.section`
    header {
      padding: 40px 0 40px;
      text-align: center;
      
      
      .logo {
        max-width: 200px;
        width: 100%;
        display: inline-block;

        img {
          width: 100%;
        }
      }
    }
  
  .content {
    padding: 40px 0 120px;
    .page-title {
      text-align: center;
    }
    .title {
      text-align: center;
      padding-top: 32px;
    }
    
    .block-title {
      padding-top: 20px;
    }
    
    .block {
      padding-top: 12px;
      padding-bottom: 40px;
      line-height: 1.5;
      font-size: ${({theme}) => theme.fontSizes.md};
      text-align: justify;
    }
    span {
      color: ${({theme}) => theme.colors.primary};
    }
    
    ul {
      margin: 12px 0;
      li {
        list-style-type: disc;
        margin-left: 24px;
      }
    }
    .underline {
      text-decoration: underline;
    }
    .link {
      color: ${({theme}) => theme.colors.primary};
      text-decoration: underline;
    }
  }
`
